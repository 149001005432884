import styled from "styled-components"
import { animated } from 'react-spring'
import style from "../styles/main"

const Stripe = styled(animated.div).attrs(props => ({
  style: {
    marginTop: props.m + "vh",
    left: props.l + "%",
    width: props.w + "%",
    maxWidth: props.w + "%",
    height: props.h + "vh",
  }
}))`
  position: relative;
  background-image: linear-gradient(315deg, ${style.stripeColor} 10%, ${style.backgroundColor} 10% 50%, ${style.stripeColor} 50% 60%, ${style.backgroundColor} 60%);
  background-size: 10px 10px;
`;

export default Stripe
