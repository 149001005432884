import React, {useEffect, useState} from "react"
import Sidebar from "../components/sidebar"
import styled from "styled-components"
import style from "../styles/main"

const Main = styled.div`
  top: 0px;
  position: relative;
  z-index: 1;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    left: 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    left: 222px;
    width: calc(100% - 222px);
    height: 100%;
    min-height: 100vh;
  }
`

const Whole = styled.div`
  z-index: -3;
  background-color: ${style.pageBackground}
`


const Layout = ({initialHeight, children }) => {
  const [small, set] = useState(true);
  const handleResize = () => {
    if (window) {
      if (window.innerWidth >= style.mediaMinWidth) {
        set(false)
      } else {
        set(true)
      }
    }
  }
  useEffect(() => {
    if (window) {
      window.addEventListener('resize', handleResize);
      set(window.innerWidth  < style.mediaMinWidth)
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
    return null
  }, []);
  return (
    <Whole>
      <Sidebar small={small} initialHeight={initialHeight}/>
      <Main>{children}</Main>
    </Whole>
  )
}


export default Layout
