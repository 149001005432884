import React, {useState, useRef} from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import {useSpring, useSprings, useChain, animated } from 'react-spring'
import style from "../styles/main"

const MenuDiv = styled(animated.div)`
  height: 100vh;
  position: fixed;
  z-index: 101;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    top: 0px;
    left: 0px;
    width: 0px;
    height: 100%;
    background-color: ${style.backgroundColor}
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    height: 100vh;
    width: 220px;
    background-color: transparent;
  }
`;

const Hamburger = styled.div`
  position: fixed;
  top: 5px;
  width: 46px;
  height: 46px;
  z-index: 102;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    left: 5px;
    background-color: ${style.backgroundColor}
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    left: 88px;
    background-color: transparent;
  }
`;

const Bar = styled(animated.div)`
  position: absolute;
  height: 1px;
  margin: auto;
  background-color: ${style.firstColor};
  z-index: 102;
`;

const Inner = styled(animated.div)`
  position: absolute;
  transform: translateY(-50%);
  width: 82%;
  left: 9%;
  top: 50%;
`;

const InnerLarge = styled(animated.div)`

  @media not all and (min-width: ${style.mediaMinWidth}px) {
    visible: false;
    width: 0px;
    height: 0px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    position: absolute;
    transform: translateY(-50%);
    width: 74%;
    left: 13%;
    top: 50%;
  }
`;

const MenuLink = styled(animated.div)`
  text-align: center;
  margin-top: ${props => props.small ? "3em" : "1em"};
  margin-bottom: ${props => props.small ? "3em" : "1em"};
  a {
    font-family: rewirFont;
    font-size: ${props => props.small ? "3em" : "1.25em"};
    text-decoration: none;
    color: ${style.menuColor};
  }
`;

const Footer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  width: 82%;
  left: 9%;
  bottom: 20px;
  a {
    font-family: rewirFont;
    font-size: 1.25em;
    text-decoration: none;
    color: ${style.menuColor};
  }
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    bottom: 10%;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    bottom: 20px;
  }
`;

const FooterImg = styled.a`
  margin: 3px;
  width: 32px;
`;

const state = {
  NORMAL: 'normal',
  HOVER: 'hover',
  OPEN: 'open'
}

const bars = [{top: '30', r: '0deg'}, {top: '50', r: '-45deg'}, {top: '50', r: '45deg'}, {top: '70', r: '0deg'}]

function Menu(props) {
  const small = props.small
  const [menuState, setMenuState] = useState(state.NORMAL)
  const [fbIcon, setFbIcon] = useState("/icons/01b.png")
  const [instIcon, setInstIcon] = useState("/icons/02b.png")
  const menuSpringRef = useRef()
  const menuSpring = useSpring({
    ref: menuSpringRef,
    width: menuState !== state.OPEN ? 0 : 100,
    opacity: menuState !== state.OPEN ? 0 : 1,
    from: {opacity: 0, width: 0}
  })
  const pSpringRef = useRef()
  const pSpring = useSpring({
    ref: pSpringRef,
    y: menuState !== state.OPEN ? 200 : 0,
    opacity: menuState !== state.OPEN ? 0 : 1,
    from: {opacity: 0, y: 200}
  })
  const aSpringRef = useRef()
  const aSpring = useSpring({
    ref: aSpringRef,
    y: menuState !== state.OPEN ? 200 : 0,
    opacity: menuState !== state.OPEN ? 0 : 1,
    from: {opacity: 0, y: 200}
  })
  const cSpringRef = useRef()
  const cSpring = useSpring({
    ref: cSpringRef,
    y: menuState !== state.OPEN ? 200 : 0,
    opacity: menuState !== state.OPEN ? 0 : 1,
    from: {opacity: 0, y: 200}
  })
  const logoSpringRef = useRef()
  const logoSpring = useSpring({
    ref: logoSpringRef,
    y: menuState === state.OPEN ? -200 : 0,
    opacity: menuState === state.OPEN ? 0 : 1,
    from: {opacity: 1, y: 0}
  })
  useChain(
    small
    ? menuState === state.OPEN ? [menuSpringRef, pSpringRef, cSpringRef, aSpringRef] : [aSpringRef, cSpringRef, pSpringRef, menuSpringRef]
    : menuState === state.OPEN ? [logoSpringRef, pSpringRef, cSpringRef, aSpringRef] : [aSpringRef, cSpringRef, pSpringRef, logoSpringRef],
    [0, 0.2, 0.4, 0.6]
  )
  const barSprings = useSprings(bars.length, bars.map((item, i) => {
    switch (menuState) {
      case state.NORMAL:
        if (i === 0 || i === 3) return {top: item.top + '%', width: '50%', left: '25%', transform: 'rotate(0deg)'}
        return {top: item.top + '%', width: '30%', left: '35%', transform: 'rotate(0deg)'}
      case state.HOVER:
        return {top: item.top + '%', width: '50%', left: '25%', transform: 'rotate(0deg)'}
      case state.OPEN:
        if (i === 0 || i === 3) return {top: item.top + '%', width: '0%', left: '50%', transform: 'rotate(0deg)'}
        return {top: item.top + '%', width: '50%', left: '25%', transform: 'rotate(' + item.r + ')'}
      default:
        return null
    }
  }))
  const menuStyle = small === true
    ? {opacity: menuSpring.opacity, width: menuSpring.width.interpolate(width => `${width}vw`)}
    : {opacity: 1, width: `220px`}
  return (
    <>
    <Hamburger
      onMouseOver={() => setMenuState(menuState === state.OPEN ? state.OPEN : state.HOVER)}
      onFocus={() => setMenuState(menuState === state.OPEN ? state.OPEN : state.HOVER)}
      onMouseLeave={() => setMenuState(menuState === state.OPEN ? state.OPEN : state.NORMAL)}
      onFocusOut={() => setMenuState(menuState === state.OPEN ? state.OPEN : state.NORMAL)}
      onClick={() => setMenuState(menuState === state.OPEN ? state.HOVER : state.OPEN)}
    >
      {barSprings.map((b, i) => (
        <Bar key={i} style={b}/>
      ))}
    </Hamburger>
    <MenuDiv style={menuStyle} small={small}>
      <InnerLarge>
        <animated.div style={{opacity: logoSpring.opacity, transform: logoSpring.y.interpolate(y => `translateY(${y}px)`)}}><img alt="logo" src="/logo.svg"/></animated.div>
      </InnerLarge>
      <Inner>
        <MenuLink small={small} style={{opacity: pSpring.opacity, transform: pSpring.y.interpolate(y => `translateY(${y}vh)`)}}><Link to="/">Work</Link></MenuLink>
        <MenuLink small={small} style={{opacity: cSpring.opacity, transform: cSpring.y.interpolate(y => `translateY(${y}vh)`)}}><Link to="/contact">Contact</Link></MenuLink>
        <MenuLink small={small} style={{opacity: aSpring.opacity, transform: aSpring.y.interpolate(y => `translateY(${y}vh)`)}}><Link to="/about">About</Link></MenuLink>
      </Inner>

      <Footer>
        <FooterImg href="https://www.facebook.com/studiokarolinakulis/">
          <img width="32px" src={fbIcon} alt="Facebook" onMouseOver={() => setFbIcon("/icons/01a.png")} onFocus={() => setFbIcon("/icons/01a.png")} onMouseLeave={() => setFbIcon("/icons/01b.png")} onFocusOut={() => setFbIcon("/icons/01b.png")} />
        </FooterImg>
        <FooterImg href="https://www.instagram.com/studio_karolinakulis/">
          <img width="32px" src={instIcon} alt="Instagram" onMouseOver={() => setInstIcon("/icons/02a.png")} onFocus={() => setFbIcon("/icons/01a.png")}  onFocusOut={() => setInstIcon("/icons/02b.png")} onMouseLeave={() => setInstIcon("/icons/02b.png")} />
        </FooterImg>
      </Footer>

    </MenuDiv>
    </>
  )
}

export default Menu
