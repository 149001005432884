import React from "react"
import Menu from "./menu"
import Scroll from "./scroll"


function Sidebar(props) {
  return (
    <>
      <Menu small={props.small}/>
      <Scroll initialHeight={props.initialHeight}/>
    </>
  )
}

export default Sidebar
