import styled from "styled-components"
import style from "../styles/main"



const Background = styled.div`
  z-index: -2;
  position: absolute;
  @media not all and (min-width: ${style.mediaMinWidth}px) {
    visible: false;
    width: 0px;
    height: 0px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    width: 100%;
  }
`;

export default Background
