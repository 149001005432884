import React, {useEffect, useState} from "react"
import styled from "styled-components"
import style from "../styles/main"

const ScrollDiv = styled.div.attrs(props => ({
  style: {
      height: props.height + 'vh'
  }
}))`


  @media not all and (min-width: ${style.mediaMinWidth}px) {
    position: fixed;
    visibility: hidden;
    width: 0px;
    height: 0px;
  }
  @media all and (min-width: ${style.mediaMinWidth}px) {
    position: fixed;
    width: 2px;
    background: ${style.scrollColor};
    top: 0px;
    left: 220px;
  }
`;


function Scroll(props) {
  const [height, set] = useState(props.initialHeight);
  const handleScroll = () => {
    const whole = document.body.clientHeight - window.innerHeight
    const top =  window.pageYOffset
    if (whole <= 0) {
      set(100)
    } else {
      const height = Math.min(top/whole * 100, 100)
      set(height)
    }
  }
  useEffect(() => {
    if (window) {
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
    return null
  });
  return (
      <ScrollDiv height={height}/>
  )
}

export default Scroll
